

.dropdown-menu {
  height: 70px;
  overflow-y: scroll;
}

.chart-container {
    width: 100%;
    height:25vh
}

@media print {
* {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }
    
    body * {
    visibility: hidden;
  }

  header, footer { display: none;}


  #section-to-print, #section-to-print * {
    visibility: visible;
    /* background-color: rgba(255, 0, 255, .8);  */
      /* size: landscape !important; */

    }

  #section-to-print {
    position: relative;
    /* position: absolute; */
    /* width: auto; */
    min-width: 950;
  height:auto;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 0;
  padding-top: 0;
  top: -90px;
}

@page{
  /* size: auto !important; */
  /* size: landscape !important; */
  size: "letter";
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 0;
  padding-top: 0;

}

 #maptemp {
        /*border: 4px solid green;*/
        position: absolute;
    }
#map {
    /*
    width: 100px;
    height:100px;
    */
  width: 100%;
  height: 100vh;
    min-height: 100%;
    min-width: 100vh;
    /* display: block; */
        position: absolute;
  }
  
.leaflet-container {
  width: 100%;
  height: 100vh;
}
}

#custom-body {
  background-color: white;

}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

#map {
    /*
    width: 100px;
    height:100px;
    */
  width: 100%;
  height: 100%;
    min-height: 100%;
    min-width: 100%;
  }
#map2 {
    /*
    width: 100px;
    height:100px;
    */
  width: 100%;
  height: 100%;
    min-height: 100%;
    min-width: 100%;
  }

.ct-chart-custom {
  background-color: rgba(255, 255, 255, 1);
  stroke: rgba(0, 0, 0, 0.7);
  margin: 0;
    color: rgba(0, 0, 0, 0.7);
}

.ct-chart-custom .ct-label {
    color: rgba(0, 0, 0, 0.7);
}

.ct-chart-custom  .ct-chart-line {
    
    stroke: rgba(0, 0, 0, 1);;
    color: rgba(0, 0, 0, 1);
}

.ct-chart-custom .ct-series {
  stroke: saddlebrown;
  stroke-width: 4px;
}
#chart1 .ct-chart-custom .ct-series {
  /* Set the colour of this series line */
  stroke: red;
  /* Control the thikness of your lines */
  stroke-width: 5px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}
.ct-line-custom {
  stroke: black;
  stroke-width: 2px;
    fill: none;

}
.ct-series-custom {
  stroke: black;
  stroke-width: 1px;
    fill: none;

}
.ct-point-custom {
  stroke: black;
  stroke-width: 1px;
    fill: none;

}
.ct-grid-custom {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

logo1 {
        width: "75";
        height: "50";

    }
    

/*
@media print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
  }
  .container {
    width: auto;
    min-width: 750px;
  }
}
*/

/*
@media print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
  }
  .container {
    width: auto;
    min-width: 750px;
  }
}
*/

/*
@media print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
  }
  .container {
    width: auto;
    min-width: 750px;
  }
}
*/
